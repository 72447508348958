import axios from "axios";
import { store } from "../store/index";

export const BASE_URL = process.env.REACT_APP_BASEURL
  ? process.env.REACT_APP_BASEURL
  : "/api/";

const Api = axios.create({
  // baseURL: "/api/",
  baseURL: BASE_URL,
});

Api.interceptors.request.use((config) => {
  const state = store.getState();
  const { token } = state.auth;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

Api.interceptors.response.use((response) => {
  return response;
});
export default Api;
